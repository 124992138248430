<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">精品课程</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">课程列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="课程名称" class="searchboxItem ci-full">
              <span class="itemLabel">课程名称:</span>
              <el-input
                v-model="courseName"
                type="text"
                size="small"
                placeholder="请输入课程名称"
                clearable
              />
            </div>
            <div title="课程分类" class="searchboxItem ci-full">
              <span class="itemLabel">课程分类:</span>
              <el-select
                v-model="courseType"
                placeholder="请选择课程分类"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in courseTypeList"
                  :key="item.courseTypeId"
                  :label="item.name"
                  :value="item.courseTypeId"
                ></el-option>
              </el-select>
            </div>
            <div title="是否推荐到首页" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 7rem"
                >是否推荐到首页:</span
              >
              <el-select
                v-model="isRecommend"
                placeholder="请选择是否推荐到首页"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in isRecommendList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="是否上架" class="searchboxItem ci-full">
              <span class="itemLabel">是否上架:</span>
              <el-select
                v-model="isUsable"
                placeholder="请选择是否上架"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in isUsableList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="课程来源" class="searchboxItem ci-full">
              <span class="itemLabel">课程来源:</span>
              <el-select
                v-model="courseOriginId"
                placeholder="请选择课程来源"
                size="small"
                clearable
                filterable
              >
                <el-option
                  v-for="item in coursesCourceList"
                  :key="item.courseOwnerId"
                  :label="item.name"
                  :value="item.courseOwnerId"
                >
                </el-option>
              </el-select>
            </div>
            <div title="推荐行政区域" class="searchboxItem ci-full">
              <span class="itemLabel" style="width: 7.5rem">推荐行政区域:</span>
              <el-cascader
                v-model="areaId"
                separator="-"
                :options="searchCityTree"
                :props="{ emitPath: false, checkStrictly: true }"
                clearable
                placeholder="全部区域"
                size="small"
              ></el-cascader>
            </div>

            <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="getData()"
              >查询</el-button
            >
            <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="courseAdd()"
              >新增</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
                width="100"
              />
              <el-table-column
                label="课程名称"
                align="left"
                prop="courseName"
                show-overflow-tooltip
                width="280"
                fixed
              />
              <el-table-column
                label="课程分类"
                align="left"
                show-overflow-tooltip
                width="120"
                prop="courseTypeName"
              />
              <el-table-column
                label="课程排序"
                align="left"
                show-overflow-tooltip
                width="120"
                prop="courseOrder"
              />
              <el-table-column
                label="课程来源"
                align="left"
                show-overflow-tooltip
                width="120"
                prop="courseOwnerName"
              />
              <el-table-column
                label="小程序单价(元)"
                align="right"
                prop="miniappPrice"
                width="120"
                show-overflow-tooltip
              />
              <el-table-column
                label="苹果单价(元)"
                align="right"
                prop="iosPrice"
                width="120"
                show-overflow-tooltip
              />
              <el-table-column
                label="安卓单价(元)"
                align="right"
                prop="androidPrice"
                width="120"
                show-overflow-tooltip
              />
              <el-table-column
                label="订单量(个)"
                align="right"
                prop="orderNum"
                show-overflow-tooltip
                width="100"
              >
                <template slot-scope="scope">
                  {{ scope.row.orderNum || 0 }}
                </template>
              </el-table-column>
              <el-table-column
                label="播放量(次)"
                align="right"
                prop="playNum"
                show-overflow-tooltip
                width="100"
              >
                <template slot-scope="scope">
                  {{ scope.row.playNum || 0 }}
                </template>
              </el-table-column>
              <el-table-column
                label="浏览量(次)"
                align="right"
                prop="browseNum"
                show-overflow-tooltip
                width="100"
              >
                <template slot-scope="scope">
                  {{ scope.row.browseNum || 0 }}
                </template>
              </el-table-column>
              <el-table-column
                label="是否上架"
                align="center"
                show-overflow-tooltip
                prop="isUsable"
                minWidth="120"
              >
                <div slot-scope="scope" class="table-switch">
                  <el-switch
                    :width="20"
                    v-model="scope.row.isUsable"
                    @change="
                      getSwitchUsable(
                        scope.row.courseId,
                        scope.row.isUsable,
                        scope.$index
                      )
                    "
                  ></el-switch>
                </div>
              </el-table-column>
              <el-table-column
                label="是否首页显示"
                align="center"
                show-overflow-tooltip
                prop="isRecommend"
                minWidth="120"
              >
                <div slot-scope="scope" class="table-switch">
                  <el-switch
                    class="selStyle"
                    v-model="scope.row.isRecommend"
                    width="40"
                    @change="
                      getSwitchRecommend(
                        scope.row.courseId,
                        scope.row.isRecommend
                      )
                    "
                  ></el-switch>
                </div>
              </el-table-column>
              <el-table-column
                label="添加时间"
                align="center"
                show-overflow-tooltip
                prop="createTime"
                minWidth="200"
                fixed="right"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                fixed="right"
                align="center"
                width="180px"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    :disabled="!scope.row.isRecommend"
                    @click="doSetArea(scope.row.courseId)"
                    >更改推荐设置</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="EditDetail(scope.row.courseId)"
                    >编辑</el-button
                  >
                  <el-button
                    :disabled="!scope.row.update"
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="EditChange(scope.row.courseId)"
                    >更新</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      title="推荐行政区划"
      :visible.sync="DialogVisible"
      width="45%"
      center
      top="10%"
      @close="doClose"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="8rem"
        class="demo-ruleForm"
        style="width: 90%"
      >
        <el-form-item label="行政区划：" prop="name">
          <el-cascader
            v-model="ruleForm.name"
            separator="-"
            :options="cityTree"
            :props="props"
            clearable
            @change="cascaderChange"
          ></el-cascader>
        </el-form-item>
      </el-form>
      <div style="display: flex; justify-content: center">
        <el-button @click="doClose()">取消</el-button>
        <el-button class="bgc-bv" @click="doAreaSave()">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "koniptList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      props: { multiple: true, checkStrictly: true, emitPath: false },
      DialogVisible: false,
      courseName: "",
      courseType: "",
      areaId: "",
      courseOriginId: "",
      courseTypeList: [],
      coursesCourceList: [],
      isUsableList: [
        {
          value: true,
          label: "是",
        },
        {
          value: false,
          label: "否",
        },
      ],
      isRecommendList: [
        {
          value: true,
          label: "是",
        },
        {
          value: false,
          label: "否",
        },
      ],
      isUsable: "",
      isRecommend: "",
      ruleForm: {
        name: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "请选择行政区划",
            trigger: "change",
          },
        ],
      },
      cityTree: [],
      searchCityTree: [],
      tableIsRecommend: "",
      tableCourseId: "",
      stu: 0,
      areaIds: [], // 编辑回显的区域id
    };
  },
  created() {
    this.getcourseTypeList();
    this.getcoursesCourceList();
    this.getcity();
  },
  computed: {},
  methods: {
    cascaderChange(val) {
      console.log(val);
    },
    // 城市
    getcity() {
      this.$post("/sys/area/tree")
        .then((res) => {
          let resData = res.data || [];
          for (let i = 0; i < resData.length; i++) {
            for (let j = 0; j < resData[i].children.length; j++) {
              if (resData[i].children[j].children) {
                delete resData[i].children[j].children;
              }
            }
          }
          this.cityTree =
            [{ label: "全部区域", value: "all" }, ...resData] || [];
          this.searchCityTree = resData;
        })
        .catch(() => {
          return;
        });
    },
    //获取分类
    getcourseTypeList() {
      this.$post("/biz/ec/course/type/list", {}, 3000, true, 2)
        .then((ret) => {
          this.courseTypeList = ret.data || [];
        })
        .catch((err) => {
          return;
        });
    },
    //获取来源
    getcoursesCourceList() {
      this.$post("/biz/ec/course/owner/list", {}, 3000, true, 2)
        .then((ret) => {
          this.coursesCourceList = ret.data || [];
        })
        .catch((err) => {
          return;
        });
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        isUsable: this.isUsable,
        isRecommend: this.isRecommend,
      };
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.courseName) {
        params.courseName = this.courseName;
      }
      if (this.courseType) {
        params.courseTypeId = this.courseType;
      }
      if (this.courseOriginId) {
        params.courseOriginId = this.courseOriginId;
      }
      this.doFetch(
        {
          url: "/biz/ec/course/coursePageList",
          params,
          pageNum,
        },
        true,
        2
      );
    },
    //新增
    courseAdd() {
      this.$router.push({
        path: "/web/CourseManagementYw/CourseListDetailsYw",
      });
    },
    //编辑
    EditDetail(courseId) {
      this.$router.push({
        path: "/web/CourseManagementYw/CourseListDetailsYw",
        query: {
          courseId,
          stu: "edit",
        },
      });
    },
    //删除
    EditChange(courseId) {
      this.$post("/biz/ec/course/updateCourse", { courseId }, 3000, true, 2)
        .then((ret) => {
          this.$message({
            type: "success",
            message: "更新成功",
          });
          this.getData();
        })
        .catch((err) => {
          return;
        });
    },
    /* 上下架 */
    getSwitchUsable(courseId, isUsable, index) {
      this.$post(
        isUsable
          ? "/biz/ec/course/updateUsableOn"
          : "/biz/ec/course/updateUsableOff",
        { courseId },
        3000,
        true,
        2
      )
        .then((ret) => {
          // if(ret.status == 0) {
          this.$message({
            type: "success",
            message: isUsable ? "上架成功" : "下架成功",
          });
          // }
        })
        .catch((err) => {
          console.log(err);
          if (err.data.status == "-1") {
            this.tableData[index].isUsable = false;
          }
          return;
        });
    },
    /* 是否推荐到首页 */
    getSwitchRecommend(courseId, isRecommend) {
      console.log(isRecommend);
      this.tableCourseId = courseId;
      this.tableIsRecommend = isRecommend;
      if (!isRecommend) {
        this.$post(
          "/biz/ec/course/updateRecommendOff",
          { courseId },
          3000,
          true,
          2
        )
          .then((ret) => {
            this.tableCourseId = "";
            this.tableIsRecommend = "";
            this.$message({
              type: "success",
              message: "取消推荐",
            });
          })
          .catch((err) => {
            return;
          });
      } else {
        this.DialogVisible = true;
        this.stu = 1;
      }
    },
    doSetArea(courseId) {
      this.doQueryArea(courseId);
      this.tableCourseId = courseId;
      this.DialogVisible = true;
      this.stu = 0;
    },
    // 选择行政区域后保存
    doAreaSave() {
      // console.log(this.ruleForm.name);
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$post(
            "/biz/ec/course/v1/area/modify",
            {
              id: this.tableCourseId,
              areaIds: this.ruleForm.name,
            },
            3000,
            true,
            2
          )
            .then((res) => {
              // ;
              if (res.status == "0") {
                if (this.stu == 1) {
                  this.doSwitchOpen();
                } else {
                  this.doClose(1);
                }
              }
            })
            .catch(() => {
              // this.doClose(2);
            });
        }
      });
    },
    // 推荐到首页
    doSwitchOpen() {
      this.$post(
        "/biz/ec/course/updateRecommendOn",
        {
          courseId: this.tableCourseId,
        },
        3000,
        true,
        2
      )
        .then((ret) => {
          if (ret.status == "0") {
            this.$message({
              type: "success",
              message: "推荐成功",
            });
            this.doClose(1);
          } else {
            this.doClose(2);
          }
        })
        .catch((err) => {
          this.doClose(2);

          return;
        });
    },
    // 关闭弹窗回调
    doClose(status) {
      // stu == 1-->在列表操作switch时打开的dialog
      // status == 1 --> 确定保存
      if (status != 1) {
        if (this.stu == 1) {
          this.tableData.forEach((el) => {
            if (el.courseId == this.tableCourseId) {
              el.isRecommend = false;
            }
          });
        }
      } else {
      }
      this.DialogVisible = false;
      this.tableCourseId = "";
      this.ruleForm.name = "";
      this.stu = 0;
    },
    // 查询关联区划
    doQueryArea(courseId) {
      this.areaIds = [];
      this.$post(
        "/biz/ec/course/v1/area/query",
        { courseId },
        3000,
        true,
        2
      ).then((res) => {
        ;
        this.ruleForm.name = res.data || [];
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 4 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function(val, oldVal) {
        if (val.query.refresh == 'true') {
          this.getData();
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less">
.table-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  .el-switch__core {
    width: 40px !important;
  }
  .el-switch__core:after {
    background-color: #fff;
  }
  span {
    line-height: 20px;
    margin-left: 5px;
  }
}
</style>
